import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((app) => {
  Sentry.init({
    app: app.vueApp,
    dsn: "https://93b405804aa55c03e9280fafdb65c6ad@o4505662827986944.ingest.us.sentry.io/4507380681867264",
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [
      "https://meili.stanislasbdx.cc",
      "https://beta.meili.stanislasbdx.cc",
    ],
    tracesSampleRate: 0.25,

    ignoreTransactions: [
      "No match found for location with path",
      "The `compilerOptions` config",
      "Failed to load resource",
    ],
    ignoreErrors: [
      "No match found for location with path",
      "The `compilerOptions` config",
      "Failed to load resource",
    ],
  });
});
