import { defineStore } from "pinia";

export const useCartStore = defineStore("cart", {
  state: () => ({
    cartItems: {},
  }),
  getters: {
    allItemsSize() {
      let totalQuantity = 0;
      for (const productId in this.cartItems) totalQuantity += this.cartItems[productId];

      return totalQuantity;
    },
  },
  actions: {
    setItem(productId, quantity = 1) {
      this.cartItems[productId] = this.cartItems[productId] ? this.cartItems[productId] + quantity : quantity;
    },
    removeItem(productId) {
      if (!this.cartItems[productId]) return;

      delete this.cartItems[productId];
    },

    emptyCart() {
      this.cartItems = {};
    },

    setCart(cartItems) {
      this.cartItems = cartItems;
    },
  },
});
