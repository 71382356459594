<template>
  <v-footer
    color="accent"
    style="border-radius: 20px 20px 0 0;"
    :height="50"
  >
    <v-row class="mx-auto">
      <v-col class="py-0" cols="12" sm="auto">
        <h5 class="text-caption text-sand text-center">Made with<v-icon icon="far fa-heart" size="x-small" class="mb-1 mx-1" />by <NuxtLink to="https://infographart.com" class="text-sand text-decoration-none">Infographart</NuxtLink></h5>
      </v-col>

      <v-spacer />

      <v-col class="py-0" cols="12" sm="auto">
        <h5 class="text-caption text-sand text-center">
          <NuxtLink to="/terms" class="text-sand text-decoration-none">Mentions légales</NuxtLink> | <NuxtLink to="/privacy" class="text-sand text-decoration-none">Politique de confidentialité</NuxtLink>
        </h5>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "ComponentFooter",
};
</script>

<style scoped>
footer {
  position: absolute;
  bottom: 0;

  max-height: 50px;
  width: 100%;
}
</style>
