import "vuetify/styles";
import { createVuetify } from "vuetify";

import "@fortawesome/fontawesome-free/css/all.css";
import { aliases, fa } from "vuetify/iconsets/fa";

const mainTheme = {
  dark: false,
  colors: {
    background: "#F3EADB",

    sand: "#F3EADB",
    darkblue: "#303D45",

    primary: "#B39B74",
    accent: "#303D45",

    info: "#597D9D",

    nicewhite: "#f3f3f3",
  },
};

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    icons: {
      defaultSet: "fa",
      aliases,
      sets: {
        fa,
      },
    },
    theme: {
      defaultTheme: "mainTheme",
      themes: {
        mainTheme,
      },
    },
    defaults: {
      VBtn: {
        class: "rounded-xl",
      },
      VCard: {
        class: "rounded-xl",
      },
      VAlert: {
        elevation: 1,
        class: "rounded-xl",
      },
    },
  });
  app.vueApp.use(vuetify);
});
