<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps({
  error: Object as () => NuxtError
})
</script>

<template>
  <v-empty-state
    headline="Oups, une erreur s'est produite !"
    :title="displayError.message"
    :text="`Erreur ${error.statusCode} : ${error.message}`"
    :icon="displayError.icon"
    color="info"
    class="mt-16 pt-16"
  >
    <template v-slot:actions>
        <v-btn
          color="info"
          text="Retourner à l'accueil"
          @click="goHome()"
        ></v-btn>
      </template>

  </v-empty-state>
</template>

<script lang="ts">
export default {
  data: () => {
    return {
      displayError: {
        message: "",
        icon: "",
      },
    }
  },
  created() {
    if(this.error.statusCode === 404) this.displayError = {
      message: "Cette page n'existe pas",
      icon: "far fa-question",
    };
    else this.displayError = {
      message: "Le serveur a abandonné le navire..",
      icon: "fa fa-person-through-window",
    };
  },
  methods: {
    goHome() {
      this.$router.push("/");
    }
  }
}
</script>
