<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Happy+Monkey&display=swap" rel="stylesheet">


  <v-app>
    <ComponentNavigation />

    <NuxtPage />

    <ComponentFooter />
  </v-app>
</template>

<script>
import ComponentNavigation from "~/components/Navigation.vue";
import ComponentFooter from "~/components/Footer.vue";

export default {
  name: "App",
  components: {
    ComponentNavigation,
    ComponentFooter,
  },
};
</script>
