import { default as cart3AXNnOUus9Meta } from "/vercel/path0/pages/cart.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as product3PaMVqdhk9Meta } from "/vercel/path0/pages/product.vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
export default [
  {
    name: cart3AXNnOUus9Meta?.name ?? "cart",
    path: cart3AXNnOUus9Meta?.path ?? "/cart",
    meta: cart3AXNnOUus9Meta || {},
    alias: cart3AXNnOUus9Meta?.alias || [],
    redirect: cart3AXNnOUus9Meta?.redirect,
    component: () => import("/vercel/path0/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacykJ3SQnmrN4Meta?.name ?? "privacy",
    path: privacykJ3SQnmrN4Meta?.path ?? "/privacy",
    meta: privacykJ3SQnmrN4Meta || {},
    alias: privacykJ3SQnmrN4Meta?.alias || [],
    redirect: privacykJ3SQnmrN4Meta?.redirect,
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: product3PaMVqdhk9Meta?.name ?? "product",
    path: product3PaMVqdhk9Meta?.path ?? "/product",
    meta: product3PaMVqdhk9Meta || {},
    alias: product3PaMVqdhk9Meta?.alias || [],
    redirect: product3PaMVqdhk9Meta?.redirect,
    component: () => import("/vercel/path0/pages/product.vue").then(m => m.default || m)
  },
  {
    name: termsq5qlZHSSonMeta?.name ?? "terms",
    path: termsq5qlZHSSonMeta?.path ?? "/terms",
    meta: termsq5qlZHSSonMeta || {},
    alias: termsq5qlZHSSonMeta?.alias || [],
    redirect: termsq5qlZHSSonMeta?.redirect,
    component: () => import("/vercel/path0/pages/terms.vue").then(m => m.default || m)
  }
]